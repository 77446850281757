import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

function Navigation() {
  const location = useLocation();
  const [hideNav, setHideNav] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY < 50) {
        setHideNav(false);
      } else if (Math.abs(currentScrollY - lastScrollY) > 40) {
        if (currentScrollY > lastScrollY) {
          setHideNav(true);
        } else {
          setHideNav(false);
        }
      }
      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  let colorClass;
  switch (location.pathname) {
    case "/work/blaze":
      colorClass = "text-color-blaze";
      break;
    case "/work/mango-crypto-wallet":
      colorClass = "text-color-mango-crypto-wallet";
      break;
    case "/work/mango-web3-token-sale":
      colorClass = "text-color-mango-web3-token-sale";
      break;
    case "/work/space-individuals":
      colorClass = "text-color-space-individuals";
      break;
    case "/work/bitpanda-self-service":
      colorClass = "text-color-bitpanda-self-service";
      break;
    case "/work/bitpanda-boss":
      colorClass = "text-color-bitpanda-self-service";
      break;
    default:
      colorClass = "";
      break;
  }

  const getLinkClass = (path) => {
    return location.pathname === path ? "active" : "";
  };

  return (
    <nav
      className={`px-[6vw] xl:px-[12vw] 2xl:px-[16vw] fixed w-full z-50 transition-transform duration-300 ${
        hideNav ? "-translate-y-full" : "translate-y-0"
      } ${colorClass}`}
    >
      <div className="flex h-20 items-center justify-between items-top text-sm uppercase">
        <Link to="/" className={getLinkClass("/")}>
          Home
        </Link>
        <Link to="/about" className={getLinkClass("/about")}>
          About
        </Link>
        <a
          href="https://www.linkedin.com/in/zagnienski/"
          rel="noopener noreferrer"
          title="LinkedIn"
          target="_blank"
          className={`inline-flex items-center ${getLinkClass(
            "https://www.linkedin.com/in/zagnienski/"
          )}`}
        >
          <span>LinkedIn</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.7}
            stroke="currentColor"
            className="w-[10px] h-[10px] relative -top-1 left-1"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
            />
          </svg>
        </a>
        <a
          href="https://dribbble.com/igor-zagnienski"
          rel="noopener noreferrer"
          title="Dribbble"
          target="_blank"
          className={`inline-flex items-center ${getLinkClass(
            "https://dribbble.com/igor-zagnienski"
          )}`}
        >
          <span>Dribbble</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.7}
            stroke="currentColor"
            className="w-[10px] h-[10px] relative -top-1 left-1"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
            />
          </svg>
        </a>
      </div>
    </nav>
  );
}

export default Navigation;
