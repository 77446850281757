import React from "react";
import { Link, useLocation } from "react-router-dom";

function Footer() {
  const location = useLocation();

  let colorClass;
  switch (location.pathname) {
    case "/work/blaze":
      colorClass = "text-color-blaze";
      break;
    case "/work/mango-crypto-wallet":
      colorClass = "text-color-mango-crypto-wallet";
      break;
    case "/work/mango-web3-token-sale":
      colorClass = "text-color-mango-web3-token-sale";
      break;
    case "/work/space-individuals":
      colorClass = "text-color-space-individuals";
      break;
    case "/work/bitpanda-self-service":
      colorClass = "text-color-bitpanda-self-service";
      break;
    default:
      colorClass = "text-white";
      break;
  }

  return (
    <footer className="flex h-[400px] w-full px-[6vw] xl:px-[12vw] 2xl:px-[16vw] items-center text-center">
      <span className="mx-auto">
        <div class="svg-container">
          <svg width="300" height="300" viewBox="0 0 300 300">
            <path
              id="circlePath"
              d="M 150, 150 m -100, 0 a 100,60 0 1,1 200,0 a 100,60 0 1,1 -200,0"
              fill="none"
              stroke="none"
            />

            <text class="circle-text">
              <textPath
                href="#circlePath"
                className="text-[8px] uppercase tracking-[0.47em] opacity-50"
              >
                LFG 2024 LFG 2024 LFG 2024 LFG 2024 LFG 2024 LFG 2024 LFG 2024
              </textPath>
            </text>
          </svg>
          <a
            href="mailto:igor.zagnienski@gmail.com"
            className="center-text uppercase text-color-active leading-none"
          >
            Let's <br /> connect
          </a>
        </div>
      </span>
    </footer>
  );
}

export default Footer;
