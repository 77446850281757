import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import ProjectPage from "./pages/ProjectPage";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import AboutPage from "./pages/AboutPage";
import ScrollToTop from "./components/ScrollTop";

function App() {
  return (
    <div className="antialiased">
      <Router>
        <div className="mb-[400px] bg-[#000]">
          <Navigation />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/work/:projectName" element={<ProjectPage />} />
          </Routes>
        </div>
        <div className="fixed bottom-0 left-0 -z-50 overflow-hidden w-full">
          <Footer />
        </div>
      </Router>
    </div>
  );
}

export default App;
