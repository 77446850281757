import React from "react";

function AboutPage() {
  return (
    <div className="pt-48 pb-[16vw] px-[6vw] xl:px-[12vw] 2xl:px-[16vw]">
      <div className="flex flex-col md:flex-row md:space-x-12 space-y-12 md:space-y-0 pt-1">
        <div className="w-full md:w-1/2">
          <img src="igor.jpg" className="" alt="Igor Zagnienski" />
        </div>
        <div className="w-full md:w-1/2">
          <div className="w-full space-y-4 flex flex-col h-[100%]">
            <h1 className="uppercase text-[7vw] lg:text-[6vw] leading-none mb-auto -mt-[0.06em]">
              About
            </h1>
            <div className="pt-8 tracking-wide">
              Thanks for stopping by! My name is Igor. I am a UX Designer currently based
              in Vienna, working with teams around the world on building Web3
              digital products. I lead the end-to-end design process for mobile
              apps and websites, from concept to implementation. This includes
              everything from user research and wireframing to high-fidelity
              designs and usability testing. I love translating complex user
              needs and business objectives into profitable, engaging digital
              products. For the past six years, I’ve been deeply immersed in
              everything related to crypto, DeFi, Web3, and AI.
            </div>
            <ul className="pt-4 leading-tight space-y-1 uppercase text-sm">
              <li className="h-[1px] bg-[#d2d2d2] opacity-50"></li>
              <li className="flex justify-between pt-[0.3em]">
                <span>Experience</span>
                <span>15 years / 6 years in crypto</span>
              </li>
              <li className="h-[1px] bg-[#d2d2d2] opacity-50"></li>
              <li className="flex justify-between pt-[0.3em]">
                <span>Location</span>
                <span>Vienna / World / Remote</span>
              </li>
              <li className="h-[1px] bg-[#d2d2d2] opacity-50"></li>
              <li className="flex justify-between pt-[0.3em]">
                <span>Focus</span>
                <span>Fintech / Crypto / Web3</span>
              </li>
              <li className="h-[1px] bg-[#d2d2d2] opacity-50"></li>
              <li className="flex justify-between pt-[0.3em]">
                <span>Expertise</span>
                <span>UX Design / UX Research</span>
              </li>
              <li className="h-[1px] bg-[#d2d2d2] opacity-50"></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
