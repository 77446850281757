import React from "react";
import RecentProjects from "../components/RecentProjects";
import projectsData from "./projectsData";

function HomePage() {
  return (
    <div className="relative overflow-hidden">
      <div className="pt-48 pb-28 md:pb-[8vw] relative z-10">
        <div className="w-full px-[6vw] xl:px-[12vw] 2xl:px-[16vw] text-center">
          <h1 className="uppercase text-[10vw] xl:text-[8vw] leading-[0.8]">
            Crafting UX <br /> for{" "}
            <span className="text-color-active"> Web3</span>
          </h1>

           <h2 className="text-lg leading-tight mt-12 mx-auto max-w-[700px]">
            Thanks for stopping by! I'm Igor, a passionate entrepreneur and UX designer. I am the Co-founder and Head of Design at Blaze Payments (YC S24) and Co-founder of Space Individuals. I enjoy working with teams around the globe, helping them build digital products and scale their businesses.
           </h2>

        </div>
      </div>
      <div className="relative z-10">
        <RecentProjects projectsData={projectsData} />
      </div>
    </div>
  );
}

export default HomePage;
