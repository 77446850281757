import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

function RecentProjects({ projectsData }) {
  const [currentImage, setCurrentImage] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [previewPosition, setPreviewPosition] = useState({ x: 0, y: 0 });
  const [speed, setSpeed] = useState(0.1); // Speed of the image preview movement

  const location = useLocation();

  let colorClass;
  switch (location.pathname) {
    case "/work/blaze":
      colorClass = "text-color-blaze";
      break;
    case "/work/mango-crypto-wallet":
      colorClass = "text-color-mango-crypto-wallet";
      break;
    case "/work/mango-web3-token-sale":
      colorClass = "text-color-mango-web3-token-sale";
      break;
    case "/work/space-individuals":
      colorClass = "text-color-space-individuals";
      break;
    case "/work/bitpanda-self-service":
      colorClass = "text-color-bitpanda-self-service";
      break;
    case "/work/bitpanda-boss":
      colorClass = "text-color-bitpanda-boss";
      break;
    default:
      colorClass = "";
      break;
  }

  let bgClass;
  switch (location.pathname) {
    case "/work/blaze":
      bgClass = "bg-color-blaze";
      break;
    case "/work/mango-crypto-wallet":
      bgClass = "bg-color-mango-crypto-wallet";
      break;
    case "/work/mango-web3-token-sale":
      bgClass = "bg-color-mango-web3-token-sale";
      break;
    case "/work/space-individuals":
      bgClass = "bg-color-space-individuals";
      break;
    case "/work/bitpanda-self-service":
      bgClass = "bg-color-bitpanda-self-service";
      break;
    case "/work/bitpanda-boss":
      bgClass = "bg-color-bitpanda-boss";
      break;
    default:
      bgClass = "bg-color-native";
      break;
  }

  const lerp = (start, end, factor) => start + (end - start) * factor;

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    const updatePreviewPosition = () => {
      setPreviewPosition((prevPosition) => ({
        x: lerp(prevPosition.x, mousePosition.x, speed),
        y: lerp(prevPosition.y, mousePosition.y, speed),
      }));
    };

    window.addEventListener("mousemove", handleMouseMove);
    const interval = setInterval(updatePreviewPosition, 12);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      clearInterval(interval);
    };
  }, [mousePosition, speed]);

  return (
    <div
      className="px-[6vw] xl:px-[12vw] 2xl:px-[16vw] pb-[16vw]"
      id="recentProjects"
    >
      <div className="w-full mb-6 text-sm uppercase justify-between w-full flex">
        <span>Selected work</span>
        <span>2022 - 2024</span>
      </div>
      <div className="w-full">
        <div className={`h-[1px] opacity-20 flex w-full ${bgClass}`}></div>
        <ul>
          {projectsData.map((project) => (
            <li
              className="project-item relative"
              key={project.id}
              onMouseEnter={() => {
                setCurrentImage(project.thnUrl);
                setIsVisible(true);
              }}
              onMouseLeave={() => setIsVisible(false)}
            >
              <Link to={`/work/${project.id}`} className={colorClass}>
                <div className="flex flex-row w-full pt-7 md:pt-12 pb-7 md:pb-12 relative space-x-8">
                  <div className="w-full md:w-1/2 flex flex-row">
                    <div className="w-16 text-[8px] uppercase tracking-[0.54em] mt-[0.4em] opacity-50 hidden md:flex">
                      {project.number}
                    </div>
                    <div className="w-full tracking-tight md:leading-[1.2] text-2xl md:text-[3.5vw] project-title md:mt-[0em] lg:-mt-[0.1em]">
                      {project.client}
                    </div>
                  </div>
                  <div className="hidden md:flex md:flex-row w-1/2 space-x-8">
                    <div className="w-1/2 md:leading-tight md:text-lg pr-16 hidden lg:block">
                      {project.title}
                    </div>
                    <div className="w-1/2 uppercase leading-none mt-1 text-sm opacity-50 hidden md:block">
                      <p>{project.scope1}</p>
                      <p>{project.scope2}</p>
                      <p>{project.scope3}</p>
                      <p>{project.scope4}</p>
                      <p>{project.scope5}</p>
                    </div>
                  </div>
                </div>
                <div
                  className={`h-[1px] opacity-20 flex w-full ${bgClass}`}
                ></div>
              </Link>
            </li>
          ))}
        </ul>
        <div
          className={`image-preview ${isVisible ? "visible" : ""}`}
          style={{
            left: `${previewPosition.x}px`,
            top: `${previewPosition.y}px`,
          }}
        >
          <div
            style={{
              borderRadius: "0",
              width: "100%",
              height: "100%",
              backgroundImage: `url(${currentImage})`,
              backgroundSize: "100%",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              margin: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default RecentProjects;
